import { addData, getList } from "@/api/api";
import { USER_TYPE_TEACHER } from "@/utils/config";
export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        no: '',
        name: '',
        school_id: '',
        grade_level: '',
        teacher_id: '',
        teacher_name: ''
      },
      schoolList: [],
      gradeList: [],
      teacherList: []
    };
  },
  mounted() {},
  methods: {
    closeDiv() {
      this.$emit('closeDiv');
    },
    getInfo(row) {
      this.getList();
      if (row) {
        const data = JSON.parse(row);
        this.Info = {
          id: data.id || '',
          no: data.no || '',
          name: data.name || '',
          school_id: data.school_id || '',
          grade_level: data.grade_level || '',
          teacher_id: data.teacher_id || '',
          teacher_name: data.teacher_name || ''
        };
        this.getTeacherOptions();
      } else {
        this.Info = {
          id: '',
          no: '',
          name: '',
          school_id: '',
          grade_level: '',
          teacher_id: '',
          teacher_name: ''
        };
      }
    },
    getTeacherOptions() {
      getList({
        type: USER_TYPE_TEACHER,
        school_id: this.Info.school_id
      }, 'User').then(res => {
        this.teacherList = res.data;
      });
    },
    handleTeacherChange() {
      let that = this;
      if (!that.Info.teacher_id) {
        that.Info.teacher_id = '';
        that.Info.teacher_name = '';
        return;
      }
      for (let item of that.teacherList) {
        if (item.id == that.Info.teacher_id) {
          that.Info.teacher_name = item.name;
          break;
        }
      }
    },
    handleSchoolChange() {
      if (this.Info.school_id > 0) {
        this.getTeacherOptions();
      } else {
        this.teacherList = [];
      }
    },
    getList() {
      let data = {};
      getList(data, 'School').then(res => {
        this.schoolList = res.data;
      });
      getList(data, 'Grade').then(res => {
        this.gradeList = res.data;
      });
    },
    subData() {
      //提交
      if (!this.Info.no || !this.Info.name) {
        this.$root.ElMessage.error('编号和姓名不能为空！');
        return false;
      }
      addData(this.Info, 'Clazz').then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.closeDiv();
          this.$emit('getList');
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};